import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import Container from 'react-bootstrap/Container';
import TitleTextBlock from 'components/TitleTextBlock';
import GatsbyImage from 'components/common/GatsbyImage';

import { ICommentInfoBlockProps } from './model';
import './CommentInfoBlock.scss';

const CommentInfoBlock: FC<ICommentInfoBlockProps> = ({ title, text, items }) => {
  return (
    <div
      className={classnames('comment-info-block', {
        'comment-info-block--no-items': !items?.length,
      })}
      data-testid="comment-info-block"
    >
      <Container fluid>
        <TitleTextBlock title={title} text={text} />

        {items?.length ? (
          <div className="comment-info-block__items">
            {items.map(({ properties: { image, alt } }) => (
              <div className="comment-info-block__item" key={image.fallbackUrl}>
                {image?.fallbackUrl ? (
                  <div className="comment-info-block__item-image">
                    <GatsbyImage useFocalPoint fluid={image} alt={alt} />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentCommentInfoBlock on TCommentInfoBlockStructure {
    structure
    properties {
      text
      title
      items {
        properties {
          alt
          image {
            ...FragmentGatsbyImage
          }
        }
      }
    }
  }
`;

export default CommentInfoBlock;
