import React, { FC } from 'react';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { ITitleTextBlockProps } from './model';
import './TitleTextBlock.scss';

const TitleTextBlock: FC<ITitleTextBlockProps> = ({ title, text }) => {
  return (
    <div className="title-text-block">
      <DangerouslySetInnerHtml className="title-text-block__title" html={title} />
      <DangerouslySetInnerHtml className="title-text-block__text" html={text} />
    </div>
  );
};

export default TitleTextBlock;
