import React, { FC } from 'react';
import { graphql } from 'gatsby';
import classnames from 'classnames';
import { Element } from 'react-scroll';

import Layout from 'components/Layout';
import HomeBanner from 'components/HomeBanner';
import SixSymptomsBanner from 'components/SixSymptomsBanner';
import ProductFinder from 'components/ProductFinder';
import InfoSection from 'components/InfoSection';
import BodyRenderer from 'components/common/BodyRenderer';
import RelatedProducts from 'components/RelatedProducts';
import Testimonials from 'components/Testimonials';
import WhereToBuy from 'components/WhereToBuy';
import DiscoveryTextSection from 'components/DiscoveryTextSection';
import RelatedArticles from 'components/RelatedArticles';
import BootsBanner from 'components/BootsBanner';
import CustomRelatedArticles from 'components/CustomRelatedArticles';
import PromoBlock from 'components/PromoBlock';
import StaticProductsList from 'components/StaticProductsList';
import WhyInfoBlock from 'components/WhyInfoBlock';
import CommentInfoBlock from 'components/CommentInfoBlock';
import HomeProductBanner from 'components/HomeProductBanner';
import SimpleYoutubeBlock from 'components/SimpleYoutubeBlock';
import { GUARDIUM_DESIGN } from 'utils/constants';
import { getSettingsPropsByStructure } from 'utils/parseHelpers';

import './HomePage.scss';

const elements = {
  'Product Finder': ({ properties }, keyId) => <ProductFinder key={keyId} {...properties} />,
  'Promo Block': ({ properties }, keyId) => <PromoBlock key={keyId} {...properties} />,
  'Info Section': ({ properties }, keyId) => <InfoSection key={keyId} {...properties} />,
  'Related Products': ({ properties }, keyId) => <RelatedProducts key={keyId} {...properties} />,
  'Related Articles': ({ properties }, keyId) => {
    const { relatedArticlesLimit, ...props } = properties;

    return <RelatedArticles key={keyId} limit={relatedArticlesLimit} {...props} />;
  },
  'Testimonials Carousel': ({ properties }, keyId) => <Testimonials key={keyId} {...properties} />,
  'Where to buy': ({ properties }, keyId) => <WhereToBuy key={keyId} {...properties} />,
  'Discovery Section': ({ properties }, keyId) => (
    <DiscoveryTextSection key={keyId} {...properties} />
  ),
  'Boots Banner': ({ properties }, keyId) => <BootsBanner key={keyId} {...properties} />,
  'Custom Related Articles': ({ properties }, keyId) => {
    const { relatedArticlesLimit, commonSettings, titleTag, ...props } = properties;
    const ADPSettings = getSettingsPropsByStructure(commonSettings, 'ADP Related Articles');

    return (
      <CustomRelatedArticles
        key={keyId}
        limit={relatedArticlesLimit}
        {...props}
        titleTag={titleTag || ADPSettings?.commonArticleCardTitleTag}
      />
    );
  },
  'Static Products List': ({ properties }, keyId) => (
    <StaticProductsList key={keyId} {...properties} />
  ),
  'Why Info Block': ({ properties }, keyId) => <WhyInfoBlock key={keyId} {...properties} />,
  'Comment Info Block': ({ properties }, keyId) => <CommentInfoBlock key={keyId} {...properties} />,
  'Home Product Banner': ({ properties }, keyId) => (
    <HomeProductBanner key={keyId} {...properties} />
  ),
  'Simple Youtube Block': ({ properties }, keyId) => {
    const { videoId, iframeTitle, iframeHeight, iframeWidth } = properties;

    return (
      <SimpleYoutubeBlock
        key={keyId}
        videoId={videoId}
        iframeTitle={iframeTitle}
        iframeHeight={iframeHeight}
        iframeWidth={iframeWidth}
      />
    );
  },
};

const HomePage: FC<HomePageTypes.IPageBody> = ({
  data: {
    umbracoHome: {
      seoMetaTitle,
      seoMetaDescription,
      seoMetaKeywords,
      seoExternalHreflangs,
      openGraphImageUrl,
      body,
      topBanner,
      banner,
      homeProductBanner,
      tags,
      isSymptomStyle,
    },
    relatedArticles,
    relatedProducts,
    staticProductsList,
    commonSettings,
    siteSettings,
    mobileAppPromoBanner,
    header,
    footer,
  },
  pageContext: { relatedProductsLinks },
}) => {
  const articles = relatedArticles?.nodes;
  const isGuardiumDesign = !!tags?.find(({ name }) => name === GUARDIUM_DESIGN);

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
      mobileAppPromoBanner={mobileAppPromoBanner}
      isGuardiumDesign={isGuardiumDesign}
      className={classnames({ 'symptoms-style': isSymptomStyle })}
    >
      {topBanner?.length ? <HomeBanner topBanner={topBanner} /> : null}
      {banner?.length ? (
        <SixSymptomsBanner
          content={banner[0].properties.content}
          title={banner[0].properties.title}
          cards={banner[0].properties.cards}
          image={banner[0].properties.image}
        />
      ) : null}
      {homeProductBanner?.[0] ? <HomeProductBanner {...homeProductBanner[0].properties} /> : null}
      <Element name="main-content" className="element">
        <BodyRenderer
          bodyData={body}
          bodyItemProps={{
            ...{
              articles,
              relatedProducts,
              commonSettings,
              relatedProductsLinks,
              staticProductsList,
            },
          }}
          bodyStructure={elements}
        />
      </Element>
    </Layout>
  );
};

export const query = graphql`
  query HomeQuery(
    $relatedProductsLinks: [String]
    $relatedArticlesLinks: [String]
    $staticProductsListUrls: [String]
    $link: String = ""
    $lang: String = ""
    $articleSortInput: UmbracoArticlesSortInput
    $productImageDesktopSize: Int
    $productImageMobileSize: Int
    $productImageMobileQuality: Int
    $productImageDesktopQuality: Int
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          preTitle
          secText
          closeModalText
          minText
          commonArticleCardTitleTag
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    umbracoHome(link: { eq: $link }, lang: { eq: $lang }) {
      topBanner {
        ...FragmentHomePageTopBanner
      }
      banner {
        ...FragmentSymptomsCampaignBanner
      }
      homeProductBanner {
        ...FragmentHomeProductBannerStructure
      }
      isSymptomStyle
      title
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        key
        value
      }
      openGraphImageUrl
      link
      id
      relatedArticlesLinks
      body {
        ... on TPromoBlockStructure {
          structure
          properties {
            description
            subTitle
            title
            link {
              url
              name
            }
            imageContainer {
              properties {
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
            logo {
              properties {
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
        }
        ... on TCustomRelatedArticlesStructure {
          ...FragmentCustomRelatedArticles
        }
        ... on TWhyInfoBlockStructure {
          ...FragmentWhyInfoBlock
        }
        ... on TCommentInfoBlockStructure {
          ...FragmentCommentInfoBlock
        }
        ... on TRelatedArticlesStructure {
          structure
          properties {
            title
            relatedArticlesLimit: limit
            anchorName
            showInMenu
            menuTitle
          }
        }
        ... on TBootsBannerStructure {
          structure
          properties {
            labelText
            title
            button {
              name
              url
              icon
            }
            imageBtn {
              ...FragmentGatsbyImage
            }
            imageBtnAlt
            imageBg {
              ...FragmentGatsbyImage
            }
            imageBgAlt
            image {
              ...FragmentGatsbyImage
            }
            imageMobile {
              ...FragmentGatsbyImage
            }
            imageAlt
          }
        }
        ... on TTestimonialsCarouselStructure {
          structure
          properties {
            color
            title
            slides {
              properties {
                imageAlt
                image {
                  ...FragmentGatsbyImage
                }
                description
                ariaLink
                link {
                  name
                  url
                }
              }
            }
          }
        }
        ... on TProductFinderStructure {
          structure
          properties {
            description
            imageAlt
            subTitle
            title
            link {
              url
              name
            }
            image {
              ...FragmentGatsbyProps
              gatsbyImage {
                ...FragmentMiddleImage
              }
              mobileImage {
                childImageSharp {
                  fluid(maxWidth: 250) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
        ... on TDiscoverySectionStructure {
          structure
          properties {
            blocks {
              properties {
                description
                image {
                  ...FragmentGatsbyImage
                }
                imageAlt
                title
              }
            }
            color
            description
            image {
              ...FragmentGatsbyImage
            }
            imageAlt
            showInMenu
            title
            anchorName
            imagePosition
          }
        }
        ... on TRelatedProductsStructure {
          structure
          properties {
            ariaAllProducts
            limit
            title
            linkAllProducts {
              name
              url
            }
            isFamily
            hideVariant
            cardTheme
          }
        }
        ... on TStaticProductsListStructure {
          ...FragmentStaticProductsList
        }
        ... on TInfoSectionStructure {
          structure
          properties {
            imageAlt
            imageAlign
            video {
              structure
              properties {
                title
                url
                link
                duration
                ariaLabel
                videoPreviewPosterAlt
                videoPreviewPoster {
                  ...FragmentGatsbyImage
                }
                playButtonAria
              }
            }
            description
            title
            link {
              name
              url
            }
            ctaButtonType
            ariaCTA
            image {
              ...FragmentGatsbyImage
            }
            ctaButtonNotice
          }
        }
        ... on TWheretobuyStructure {
          structure
          properties {
            color
            title
            listMode
            limit
            limitMobile
            cards {
              properties {
                imageAlt
                type
                title
                link {
                  url
                  target
                  queryString
                }
                image {
                  ...FragmentGatsbyImage
                }
              }
            }
          }
        }
        ... on THomeProductBannerStructure {
          ...FragmentHomeProductBannerStructure
        }
        ... on TSimpleYoutubeBlockStructure {
          ...FragmentSimpleYoutubeBlockStructure
        }
      }
      tags {
        name
      }
    }
    relatedProducts: allProduct(filter: { link: { in: $relatedProductsLinks } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        imageFamily {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        linkFamily {
          url
        }
        currentFormat {
          properties {
            label
          }
        }
      }
    }
    staticProductsList: allProduct(filter: { link: { in: $staticProductsListUrls } }) {
      nodes {
        ...FragmentStaticProductCard
      }
    }
    relatedArticles: allUmbracoArticles(
      filter: { link: { in: $relatedArticlesLinks } }
      sort: $articleSortInput
    ) {
      nodes {
        id
        link
        title
        shortTitle
        label
        text
        alt
        shortText
        readMore
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          articleCategory
          id
          name
        }
      }
    }
  }
`;

export default HomePage;
