import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import CardRelatedArticle from 'components/CardRelatedArticle';
import ControlledCarousel from 'components/ControlledCarousel';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import responsiveObj from './constants';
import { IRelatedArticlesProps } from './model';
import './RelatedArticles.scss';
import './RelatedArticlesOverride.scss';

const RelatedArticles: FC<IRelatedArticlesProps> = ({
  limit,
  articles,
  title,
  commonArticleCardTitleTag,
}) => {
  return articles?.length > 0 ? (
    <div className="gs-related-articles">
      <Container fluid>
        {title ? (
          <DangerouslySetInnerHtml
            element="h2"
            className="gs-related-articles__title"
            html={title}
          />
        ) : null}
        {articles?.length > 0 ? (
          <ControlledCarousel
            dotsOutside
            showDots
            showNavigators
            classes="gs-articles-carousel"
            responsiveObj={responsiveObj}
            navigatorsOutside
          >
            {articles.slice(0, limit || articles.length).map((article, index) => {
              const id = `${article.title}_${index}`;

              return (
                <CardRelatedArticle
                  key={id}
                  articleData={{
                    ...article,
                    titleTag: article?.titleTag || commonArticleCardTitleTag,
                  }}
                />
              );
            })}
          </ControlledCarousel>
        ) : null}
      </Container>
    </div>
  ) : null;
};
export default RelatedArticles;
