import React, { FC } from 'react';
import { graphql } from 'gatsby';

import GatsbyImage from 'components/common/GatsbyImage';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';

import { BannerCardProps } from './models';
import './BannerCard.scss';

const BannerCard: FC<BannerCardProps> = ({ card, isDesktop }) => {
  const {
    image: [
      {
        properties: { image },
      },
    ],
    content,
    title,
    subTitle,
  } = card;

  return (
    <div data-testid="BannerCard" className="banner-card">
      {isDesktop ? (
        <div className="banner-card__title-block">
          <h4 className="banner-card__title">{title}</h4>
          {subTitle ? <div className="banner-card__sub-title">{subTitle}</div> : null}
        </div>
      ) : null}
      <GatsbyImage className="banner-card__image" useFocalPoint fluid={image} alt={image.altText} />
      <DangerouslySetInnerHtml className="banner-card__content" html={content} />
    </div>
  );
};

export const query = graphql`
  fragment FragmentBannerCard on TSymptomsCampaignBannerCard {
    properties {
      content
      subTitle
      title
      image {
        properties {
          image {
            ...FragmentGatsbyImage
          }
        }
      }
    }
  }
`;

export default BannerCard;
