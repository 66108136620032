import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import TitleTextBlock from 'components/TitleTextBlock';
import GatsbyImage from 'components/common/GatsbyImage';

import { IWhyInfoBlockProps } from './model';
import './WhyInfoBlock.scss';

const WhyInfoBlock: FC<IWhyInfoBlockProps> = ({ title, text, items }) => {
  return (
    <div className="why-info-block" data-testid="why-info-block">
      <Container fluid>
        <TitleTextBlock title={title} text={text} />

        {items?.length ? (
          <div className="why-info-block__items">
            {items.map(({ properties: { image, alt } }) => (
              <div className="why-info-block__item" key={image.fallbackUrl}>
                {image?.fallbackUrl ? (
                  <div className="why-info-block__item-image">
                    <GatsbyImage useFocalPoint fluid={image} alt={alt} />
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentWhyInfoBlock on TWhyInfoBlockStructure {
    structure
    properties {
      text
      title
      items {
        properties {
          alt
          image {
            ...FragmentGatsbyImage
          }
        }
      }
    }
  }
`;

export default WhyInfoBlock;
