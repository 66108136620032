import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import useScreenRecognition from 'hooks/useScreenRecognition';

import GatsbyImage from 'components/common/GatsbyImage';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';

import { IPromoBlockProps } from './model';
import './PromoBlock.scss';

const PromoBlock: FC<IPromoBlockProps> = ({
  description,
  title,
  subTitle,
  logo,
  imageContainer,
  link,
}) => {
  const { isDesktop } = useScreenRecognition();
  return (
    <div data-testid="PromoBlock" className="promo-block">
      <Container className="promo-block__container" fluid>
        <div className="promo-block__left">
          <GatsbyImage
            className="promo-block__logo"
            useFocalPoint
            fluid={logo[0].properties.image}
            alt={logo[0].properties.imageAlt}
          />
          <h3 className="promo-block__title">{title}</h3>
          <h3 className="promo-block__sub-title">{subTitle}</h3>
          <p className="promo-block__description">{description}</p>

          {isDesktop ? (
            <Button classes="promo-block__cta" to={link[0].url} variant="buy">
              {link[0].name}
              <IconCustom icon="chevron-down" />
            </Button>
          ) : null}
        </div>

        {imageContainer?.length ? (
          <GatsbyImage
            className="promo-block__image"
            useFocalPoint
            fluid={imageContainer?.[0].properties.image}
            alt={imageContainer?.[0].properties.imageAlt}
          />
        ) : null}
        {!isDesktop ? (
          <Button classes="promo-block__cta" to={link[0].url} variant="buy">
            {link[0].name}
            <IconCustom icon="chevron-down" />
          </Button>
        ) : null}
      </Container>
    </div>
  );
};

export const query = graphql`
  fragment FragmentPromoBlock on TSixPromoBlock {
    structure
    properties {
      description
      subTitle
      title
      link {
        url
        name
      }
      imageContainer {
        properties {
          imageAlt
          image {
            ...FragmentGatsbyImage
          }
        }
      }
      logo {
        properties {
          imageAlt
          image {
            ...FragmentGatsbyImage
          }
        }
      }
    }
  }
`;

export default PromoBlock;
