import React from 'react';
import { graphql } from 'gatsby';
import { Link } from 'react-scroll';

import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml';
import IconCustom from 'components/common/IconCustom';
import GatsbyImage from 'components/common/GatsbyImage';
import ExternalLink from 'components/common/ExternalLink';

import { IHomeProductBanner } from './model';

import './HomeProductBanner.scss';

const HomeProductBanner: IHomeProductBanner = ({
  bannerTitle,
  description,
  image,
  imageAlt,
  scrollAnchor,
  scrollAria,
  link,
}) => {
  const { url, target, name } = link?.[0] || {};

  return (
    <div className="home-product-banner">
      <div className="home-product-banner__container">
        <h1 className="home-product-banner__title">
          {url ? (
            <ExternalLink
              to={url}
              target={target}
              ariaLabel={name}
              className="home-product-banner__link"
            >
              <HomeProductBanner.TitleLines bannerTitle={bannerTitle} />
            </ExternalLink>
          ) : (
            <HomeProductBanner.TitleLines bannerTitle={bannerTitle} />
          )}
        </h1>
        <DangerouslySetInnerHtml className="home-product-banner__description" html={description} />
        <div className="home-product-banner__image">
          <GatsbyImage
            isLazyLoading={false}
            className="home-product-banner__image-img"
            fluid={image}
            alt={imageAlt}
            objectFit="contain"
          />
        </div>
      </div>
      {scrollAnchor ? (
        <div className="home-product-banner__sticky-link">
          <Link
            to={scrollAnchor}
            offset={-50}
            smooth
            duration={300}
            role="button"
            name={scrollAria}
            aria-label={scrollAria}
            tabIndex="0"
          >
            <IconCustom icon="arrow_down" />
          </Link>
        </div>
      ) : null}
    </div>
  );
};

HomeProductBanner.TitleLines = ({ bannerTitle }) => (
  <>
    {bannerTitle.map(({ value }) => (
      <DangerouslySetInnerHtml
        key={value}
        className="home-product-banner__text-line"
        element="span"
        html={value}
      />
    ))}
  </>
);

export const query = graphql`
  fragment FragmentHomeProductBannerStructure on THomeProductBannerStructure {
    structure
    properties {
      bannerTitle {
        value
      }
      description
      link {
        url
        name
        queryString
        target
      }
      image {
        ...FragmentGatsbyProps
        gatsbyImage {
          childImageSharp {
            fluid(maxWidth: 620, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      imageAlt
      scrollAria
      scrollAnchor
    }
  }
`;

export default HomeProductBanner;
