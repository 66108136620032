import React, { FC } from 'react';
import Accordion from 'react-tiny-accordion';

import useScreenRecognition from 'hooks/useScreenRecognition';
import DangerouslySetInnerHtml from 'components/common/DangerouslySetInnerHtml/DangerouslySetInnerHtml';
import Button from 'components/common/Button';
import IconCustom from 'components/common/IconCustom';
import BannerCard from 'components/SymptomsCampaignBanner/BannerCard/BannerCard';
import ControlledCarousel from 'components/ControlledCarousel/ControlledCarousel';
import GatsbyImage from 'components/common/GatsbyImage';
import { responsive } from './constants';

import { SixSymptomsBannerProps } from './models';

import './SixSymptomsBanner.scss';

const SixSymptomsBanner: FC<SixSymptomsBannerProps> = ({ content, title, cards, image }) => {
  const {
    properties: { numberTitle, number, title: titleAboveNumber, subTitle },
  } = title[0];

  const { properties: bannerImage } = image?.[0];
  const { isDesktop } = useScreenRecognition();

  return (
    <div data-testid="SixSymptomsBanner" className="six-symptoms-banner__wrapper">
      <div className="six-symptoms-banner">
        <div className="six-symptoms-banner__top-block">
          <div className="left-white-line" />
          <div className="left-blue-line" />
          <div className="left-pink-line" />
          <div className="left-tiny-line" />
          <div className="right-tiny-line" />
          <div className="right-green-line" />
          <div className="top-block__title">
            <div className="top-title">
              <DangerouslySetInnerHtml
                className="top-block__title-above-number"
                html={titleAboveNumber}
              />
              <div className="top-block__number">{number}</div>
              <div className="top-block__number-title">{numberTitle}</div>
              <div className="top-block__sub-title">{subTitle}</div>
            </div>
            <DangerouslySetInnerHtml className="top-block__content" html={content} />
            <div className="six-symptoms-banner__image-wrapper">
              <GatsbyImage
                className="six-symptoms-banner__image"
                useFocalPoint
                fluid={bannerImage.image}
                alt={bannerImage.image.altText}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="six-symptoms-banner__bottom-block">
        {isDesktop ? (
          <ControlledCarousel showNavigators responsiveObj={responsive}>
            {cards.map(({ properties: { title: cardTitle, ...rest } }) => (
              <BannerCard card={{ title: cardTitle, ...rest }} isDesktop={isDesktop} />
            ))}
          </ControlledCarousel>
        ) : (
          cards.map(({ properties: { title: cardTitle, subTitle: cardSubTitle, ...rest } }) => (
            <Accordion
              key={cardTitle}
              className="six-symptoms-banner__accordion"
              transitionDuration="300"
            >
              <div
                className="six-symptoms-banner__accordion__item"
                data-header={
                  <Button variant="icon" classes="six-symptoms-banner__accordion__button">
                    <IconCustom icon="chevron-down" />
                    <div className="six-symptoms-banner__accordion__button-block">
                      <div className="six-symptoms-banner__accordion__title">{cardTitle}</div>
                      <div className="six-symptoms-banner__accordion__sub-title">
                        {cardSubTitle}
                      </div>
                    </div>
                  </Button>
                }
              >
                <BannerCard card={{ title: cardTitle, ...rest }} isDesktop={isDesktop} />
              </div>
            </Accordion>
          ))
        )}
      </div>
    </div>
  );
};

export default SixSymptomsBanner;
