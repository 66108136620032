import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { ISimpleYoutubeBlockProperties } from './model';
import './SimpleYoutubeBlock.scss';

const SimpleYoutubeBlock: FC<ISimpleYoutubeBlockProperties> = ({
  videoId,
  iframeTitle,
  iframeWidth,
  iframeHeight,
}) => {
  return (
    <div className="simple-youtube-block">
      <div className="container-fluid">
        <iframe
          loading="lazy"
          width={iframeWidth || '640'}
          height={iframeHeight || '360'}
          src={`https://www.youtube-nocookie.com/embed/${videoId}?rel=0&modestbranding=1&enablejsapi=1`}
          title={iframeTitle}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment FragmentSimpleYoutubeBlockStructure on TSimpleYoutubeBlockStructure {
    properties {
      videoId
      iframeTitle
      iframeHeight
      iframeWidth
    }
    structure
  }
`;

export default SimpleYoutubeBlock;
