import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';

import ProductCard from 'components/ProductCard';
import { sortProductsAdvanced } from 'utils/helpers';

import { IStaticProductsListProps } from './model';

import './StaticProductsList.scss';

const StaticProductsList: FC<IStaticProductsListProps> = ({ title, staticProductsList }) => {
  const itemsToRender = useMemo(() => {
    return staticProductsList?.nodes?.length
      ? sortProductsAdvanced(staticProductsList.nodes, ['order'])
      : [];
  }, [staticProductsList]);

  return itemsToRender.length ? (
    <div className="static-products-list">
      <Container fluid>
        <h2 className="static-products-list__title">{title}</h2>
        <div className="static-products-list__items">
          {itemsToRender.map((product) => {
            return (
              <ProductCard
                key={product?.cardTitle}
                {...{ ...product, isFamily: '1' }}
                className="static-product-card"
                hideVariant="1"
              />
            );
          })}
        </div>
      </Container>
    </div>
  ) : null;
};

export const query = graphql`
  fragment FragmentStaticProductCard on Product {
    link
    productImage {
      ...FragmentGatsbyProps
      gatsbyImage {
        ...FragmentSmallProductImage
      }
      mobileImage {
        childImageSharp {
          fluid(maxHeight: $productImageMobileSize, quality: $productImageMobileQuality) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    productImageAlt
    cardTitle
    familyCardTitle
    tickItems {
      value
    }
    disclaimer
    tags {
      isProductFamily
      color {
        label
      }
    }
    cartFakeLink
    currentFormat {
      properties {
        label
      }
    }
    order
  }

  fragment FragmentStaticProductsList on TStaticProductsListStructure {
    structure
    properties {
      title
    }
  }
`;

export default StaticProductsList;
